import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de calze amb un peu molt curt o inexistent amb dos plecs longitudinals i enfonsat dins la terra. El capell de fins a 4 cm d’alt i 1,5 cm d’amplada al principi és tancat i poc a poc s’obre, és negrós, un poc més clar al marge i a la part inferior. Les espores són blanques en massa, el·líptiques, llises i amb una gota lipídica central, de 20-24 x 11-13 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      